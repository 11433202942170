import { Box } from 'braid-design-system';
import { useContext, type ReactNode } from 'react';

import MenuContext from './Context';

interface Props {
  children: ReactNode;
}

const Content = ({ children }: Props) => {
  const { isExpanded } = useContext(MenuContext);

  if (!isExpanded) {
    return null;
  }

  return <Box>{children}</Box>;
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Content;
