import { Box, IconChevron, Text } from 'braid-design-system';
import { useContext, type ReactNode } from 'react';

import MenuContext from './Context';

interface Props {
  text: string | ReactNode;
  leftIcon?: ReactNode;
}

const Title = ({ text, leftIcon }: Props) => {
  const { isExpanded, setIsExpanded } = useContext(MenuContext);

  return (
    <Box
      alignItems="center"
      cursor="pointer"
      display="flex"
      flexDirection="row"
      justifyContent="spaceBetween"
      onClick={() => setIsExpanded(!isExpanded)}
      padding="medium"
    >
      <Text weight={isExpanded ? 'strong' : 'regular'}>
        {leftIcon}
        {text}
      </Text>
      <IconChevron direction={isExpanded ? 'up' : 'down'} size="small" />
    </Box>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default Title;
