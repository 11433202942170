import { Box, Divider, Stack } from 'braid-design-system';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Drawer from 'src/common/UI/Drawer';
import { useACL } from 'src/hooks/useACL/useACL';
import useWindow from 'src/hooks/useWindow';
import { selectors as userSelectors } from 'src/store/user';
import { ACL } from 'src/types';

import { AccountSwitcher } from '../AccountSwitcher/AccountSwitcher';
import ActiveAccount from '../ActiveAccount/ActiveAccount';
import SavedSearchList from '../SavedSearchList/SavedSearchList';
import { SignOut } from '../SignOut/SignOut';
import TrialWarning from '../TrialWarning/TrialWarning';
import User from '../User/User';

import MenuLanguageSwitcher from './MenuLanguageSwitcher/MenuLanguageSwitcher';
import PageLinks from './PageLinks/PageLinks';

import * as styles from './MenuDrawer.css';

const MENU_OPTION_SELECT_TIMEOUT = 200;

const MenuDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { scrollToTop } = useWindow();
  const { hasAccess } = useACL();

  const userFirstName = useSelector(userSelectors.getUserFirstNameSelector);
  const advertiserName = useSelector(userSelectors.getAdvertiserNameSelector);
  const accountNumber = useSelector(
    userSelectors.getAdvertiserAccountNumberSelector,
  );
  const isConnectedTalentSearchUser = useSelector(
    userSelectors.isConnectedTalentSearchUserSelector,
  );
  const premiumContract = useSelector(userSelectors.getPremiumContract);

  const handleSelect = () => {
    scrollToTop();

    const timeout = setTimeout(() => {
      setIsOpen(false);
      clearTimeout(timeout);
    }, MENU_OPTION_SELECT_TIMEOUT);
  };

  const isOnTrial = premiumContract?.type === 'trial';

  return (
    <>
      <User
        userFirstName={userFirstName}
        advertiserName={advertiserName}
        isConnectedTalentSearchUser={isConnectedTalentSearchUser}
        isOnTrial={isOnTrial}
        isToggled={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />

      <Drawer
        openFrom="top"
        isOpen={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        disableScrollLock
        disableOverlay
        theme={{
          drawerClassName: styles.drawer,
          drawerEnterClassName: styles.drawerEnter,
          drawerExitClassName: styles.drawerExit,
        }}
      >
        <Box background="surface" className={styles.container}>
          <Box background="body" padding="medium">
            <Stack space="medium">
              <ActiveAccount
                advertiserName={advertiserName}
                userFirstName={userFirstName}
                accountNumber={accountNumber}
              />
              <PageLinks onSelect={handleSelect} linkType="credit" />
            </Stack>
          </Box>
          <Box paddingX="medium">
            <Divider />
          </Box>
          {isOnTrial && premiumContract && (
            <TrialWarning premiumContract={premiumContract} />
          )}
          <PageLinks onSelect={handleSelect} linkType="common" />
          <Box paddingX="medium">
            <Divider />
          </Box>
          {hasAccess(ACL.SEARCH_FEATURE.SAVED_SEARCHES) && (
            <>
              <SavedSearchList onSelect={handleSelect} hasScroll={false} />
              <Box paddingX="medium">
                <Divider />
              </Box>
            </>
          )}
          <MenuLanguageSwitcher />
          <Box paddingX="medium" paddingBottom="medium">
            <Divider />
          </Box>
          <AccountSwitcher />
          <SignOut />
        </Box>
      </Drawer>
    </>
  );
};

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default MenuDrawer;
