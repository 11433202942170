import { URL_CANDIDATES } from '@seek/adv-constants';
import { createUrlResolver, languages } from '@seek/melways-sites';
import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

import {
  type AppConfig,
  Environment,
  ErrorTypes,
  type ProfileTabNames,
  type ProfileTypes,
  type SimilarCandidateExtract,
} from 'src/types';
import type { SalaryFrequency } from 'src/types/generated';

export const createRoutes = ({
  urlResolver,
}: {
  urlResolver: ReturnType<typeof createUrlResolver>;
}) => ({
  urlResolver, // In case we want to use urlResolver directly

  // TS links
  help: ({ fragmentIdentifier }: { fragmentIdentifier?: string }) => {
    const path = `/talentsearch/help${
      fragmentIdentifier ? `#${fragmentIdentifier}` : ''
    }`;
    return urlResolver({ path });
  },
  searchCoupled: () => urlResolver({ path: '/talentsearch/search/job' }),
  searchUncoupled: () => urlResolver({ path: '/talentsearch/search' }),
  searchResultsCoupled: ({ jobId }: { jobId: string }) => {
    const path = `/talentsearch/search/job/${jobId}/profiles`;
    return urlResolver({ path });
  },
  searchResultsUncoupled: () => {
    const path = `/talentsearch/search/profiles`;
    return urlResolver({ path });
  },
  profile: ({
    profileId,
    ...searchParams
  }: {
    profileId: number;
    profileType: ProfileTypes;
    pageNumber?: number;
    profilePosition?: number;
    jobId?: number;
    keywords?: string;
    nation?: number;
    seedProfileId?: number;
    tab?: ProfileTabNames;
    salaryNation?: string;
    salaryType?: string;
  }) => {
    const searchString = parseSearchParams(searchParams);
    const path = `/talentsearch/profile/${profileId}${searchString}`;
    return urlResolver({ path });
  },
  pooling: () => {
    const path = '/talentsearch/pooling';
    return urlResolver({ path });
  },
  poolingChannel: ({
    id,
    channel,
    ...searchParams
  }: {
    id: string;
    channel: string; // FIXME: Add type for channel. Is it just 'pool'? Because some places use generic `string`.
    pageNumber?: string;
  }) => {
    const searchString = parseSearchParams(searchParams);
    const path = `/talentsearch/pooling/${channel}/${id}${searchString}`;
    return urlResolver({ path });
  },
  similarCandidates: ({
    seedProfileId,
    ...searchParams
  }: {
    seedProfileId: number;
    salaryType: SalaryFrequency;
    salaryNation: string;
    jobId?: number;
  } & SimilarCandidateExtract) => {
    const searchString = parseSearchParams(searchParams);
    const path = `/talentsearch/similar-candidates/${seedProfileId}${searchString}`;
    return urlResolver({ path });
  },
  error: ({
    errorType,
    ...searchParams
  }: {
    errorType: ErrorTypes;
    code: string;
    jobId?: number | undefined;
    advertiserId?: number | undefined;
  }) => {
    const pathWithoutSearchString =
      errorType === ErrorTypes.UNKNOWN
        ? '/talentsearch/error'
        : `/talentsearch/error/${errorType}`;
    const searchString = parseSearchParams(searchParams);
    return urlResolver({ path: `${pathWithoutSearchString}${searchString}` });
  },

  // others
  candidates: (params: { jobid: string; page?: 'notes' }) => {
    const queryString = parseSearchParams(params);
    const path = `${URL_CANDIDATES}${queryString}`;
    return urlResolver({ path });
  },
  jobs: () => urlResolver({ path: '/jobs' }),
});

/**
 * normaliseSearchParams
 *
 * @description Function that takes an object and return a new object without undefined or null key-value
 * Useful to clean up data before putting into new URLSearchParams() to avoid printing out ?param1=undefined&param2=null
 *
 * @example:
 * Input:
 * {
 *   id: 'something',
 *   name: null,
 *   age: undefined
 * }
 *
 * Output:
 * {
 *   id: 'something'
 * }
 */
const normaliseSearchParams = (
  params: Record<string, string | number | undefined | null>,
): Record<string, string> => {
  const result: Record<string, string> = {};

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      result[key] = `${value}`;
    }
  });

  return result;
};

const parseSearchParams = (
  queryObject: Record<string, string | number | undefined | null>,
) => {
  const searchString = new URLSearchParams(
    normaliseSearchParams(queryObject),
  ).toString();

  return searchString ? `?${searchString}` : '';
};

interface ProviderState {
  routes: ReturnType<typeof createRoutes>;
}

const RoutesContext = createContext<ProviderState | undefined>(undefined);

export const RoutesProvider: FC<
  PropsWithChildren<{
    site: AppConfig['SITE'];
    environment: AppConfig['ENVIRONMENT'];
    locale: AppConfig['LOCALE'];
  }>
> = ({ site, environment, locale, children }) => {
  const [routes] = useState(() =>
    createRoutes({
      urlResolver: createUrlResolver({
        language: locale,
        site,
        staging: environment === Environment.Staging,
      }),
    }),
  );

  return (
    <RoutesContext.Provider value={{ routes }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  const context = useContext(RoutesContext);

  if (!context) {
    throw new Error('useRoutes must be used within a RoutesProvider');
  }

  return context;
};

/**
 * getWindowPathWithoutLanguage
 * @description This is copied over from adv-header-footer for its switch language functionality.
 * Once we migrate over to the standard EmployerHeader, this can be removed as language switching is built into that header.
 */
export const getWindowPathWithoutLanguage = (): string => {
  if (typeof window === 'undefined') {
    return '/';
  }

  let path = window.location.pathname;

  const pathArray = path.split('/');
  if (languages.find((language) => language === pathArray[1])) {
    pathArray.splice(1, 1);

    path = pathArray.join('/') || '/';
  }

  return path + window.location.search + window.location.hash;
};
