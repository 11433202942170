import { datadogRum } from '@datadog/browser-rum';
import { useRef, useEffect, useMemo } from 'react';

import { Environment } from '../../types';
import { useAppConfig } from '../useAppConfig';

import Context from './Context';
import type { ContextType } from './types';

interface Props {
  children: React.ReactNode;
}

function DatadogRumProvider({ children }: Props) {
  const hasInjected = useRef<boolean>(false);
  const { APP_VERSION, ENVIRONMENT, DATADOG_RUM_CONFIG } = useAppConfig();

  useEffect(() => {
    if (hasInjected.current || ENVIRONMENT !== Environment.Production) {
      return;
    }

    datadogRum.init({
      ...DATADOG_RUM_CONFIG,
      site: 'datadoghq.com',
      env: ENVIRONMENT,
      version: APP_VERSION,
      sessionSampleRate: 20,
      sessionReplaySampleRate: 1,
      trackUserInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        /https:\/\/talent\.seek\.com\.au(?!\/graphql).*/,
        /https:\/\/talent\.seek\.co\.nz(?!\/graphql).*/,
        /https:\/\/talent\.staging\.seek\.com\.au(?!\/graphql).*/,
        /https:\/\/talent\.staging\.seek\.co\.nz(?!\/graphql).*/,
        // Asia Domains
        /https:\/\/ph\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/ph\.employer\.staging\.seek\.com(?!\/graphql).*/,
        /https:\/\/th\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/th\.employer\.staging\.seek\.com(?!\/graphql).*/,
        /https:\/\/my\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/my\.employer\.staging\.seek\.com(?!\/graphql).*/,
        /https:\/\/hk\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/hk\.employer\.staging\.seek\.com(?!\/graphql).*/,
        /https:\/\/id\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/id\.employer\.staging\.seek\.com(?!\/graphql).*/,
        /https:\/\/sg\.employer\.seek\.com(?!\/graphql).*/,
        /https:\/\/sg\.employer\.staging\.seek\.com(?!\/graphql).*/,
      ],
      beforeSend: (event) =>
        !(
          event.type === 'error' &&
          (event.error.message.includes('Login required') ||
            event.error.message.includes('Load failed') ||
            event.error.message.includes('Script error.'))
        ),
    });

    datadogRum.startSessionReplayRecording();

    hasInjected.current = true;
  }, [ENVIRONMENT, APP_VERSION, DATADOG_RUM_CONFIG]);

  const context = useMemo<ContextType>(
    () => ({
      setUser(user: { id: string }): void {
        try {
          datadogRum.setUser(user);
        } catch {}
      },
      setGlobalContext: (globalContext) => {
        try {
          const tags = {
            ...datadogRum.getGlobalContext(),
            ...globalContext,
          };
          datadogRum.setGlobalContext(tags);
        } catch {}
      },
      logError: (message, errorContext) => {
        try {
          const error = errorContext?.error ?? new Error(message);
          datadogRum.addError(error, {
            ...errorContext,
            message,
          });
        } catch {}
      },
    }),
    [],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
}

// Todo - convert this to a named export
// eslint-disable-next-line import/no-default-export
export default DatadogRumProvider;
